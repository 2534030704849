/* plans section */

section.plans {
  padding: 96px 2vw 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

section.plans .plan-cards {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  width: var(--max-content-width);
  max-width: 100%;
  gap: 40px;
  padding: 0px 12px;
}

section.plans .plan-cards .paid {
  flex: 6;
  max-width: 100%;
}

section.plans .plan-cards .paid .tabs-title {
  display: flex;
}

section.plans .plan-cards .paid .tabs-title div {
  flex: 1;
  font-size: 20px;
  line-height: 64px;
  user-select: none;
  text-align: center;
  background-color: #f5f5f7;
  cursor: pointer;
}

section.plans .plan-cards .paid .tabs-title div.active {
  background-color: #e6f2ff;
  font-weight: 700;
  z-index: 1;
  box-shadow: 0 0 19px orange;
  position: relative;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.14);
}

/* obscure bottom box-shadown, see: https://stackoverflow.com/questions/19524380/remove-box-shadow-from-bottom */
section.plans .plan-cards .paid .tabs-title div.active::after {
  background-color: #e6f2ff;
  height: 24px;
  content: "";
  position: absolute;
  bottom: -24px;
  left: 0px;
  right: 0px;
}

section.plans .plan-cards .paid .tabs-title div:nth-child(1) {
  border-top-left-radius: 32px;
  border-top-right-radius: 16px;
}

section.plans .plan-cards .paid .tabs-title div:nth-child(2) {
  border-top-left-radius: 16px;
  border-top-right-radius: 32px;
}

section.plans .plan-cards .paid .tabs-title div:nth-child(1).active::after {
  right: -24px;
}

section.plans .plan-cards .paid .tabs-title div:nth-child(2).active::after {
  left: -24px;
}

section.plans .plan-cards .paid .tabs-content {
  max-width: 100%;
  padding: 24px 40px 40px;
  background-color: #e6f2ff;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.14);
}

section.plans .plan-cards .paid .tabs-content > div {
  max-width: 100%;
  display: flex;
  gap: 48px;
}

/* mobile view */

@media screen and (max-width: 768px) {
  section.plans .plan-cards .paid .tabs-content {
    padding: 24px 16px;
  }
  section.plans .plan-cards .paid .tabs-content > div {
    flex-wrap: wrap;
    gap: 24px;
    overflow-x: auto;
  }
}

/* features section */

section.feature-comparison {
  padding: 52px 6vw 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seat-type {
  padding: 62px 6vw;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  background-color: #F5F5F7;
}

.seat-type .seat-desc {
  flex: 5;
  max-width: 500px;
}

.seat-type .seat-table {
  flex: 8;
  max-width: 800px;
}

.seat-type .seat-table th,
.seat-type .seat-table td {
  border-bottom: 1px solid #DFDFDF;
}

.seat-type .seat-table th,
.seat-type .seat-table td {
  padding: 12px;
}