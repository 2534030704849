.launch-portal-button {
  padding: 0px 24px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
}

.launch-portal-button-dark {
  border-color: #C8C7CC;
  color: #dfdfdf;
}

.launch-portal-button-light {
  border-color: #333;
  color: #333;
}

.launch-portal-button-dark:hover {
  border-color: #fff;
}

.launch-portal-button-light:hover {
  border-color: #000;
}