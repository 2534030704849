table.feauture-comparison-table {
  border-collapse: collapse;
  width: 100%;
  max-width: var(--max-content-width);
  color: #333;
}

.feauture-comparison-table th,
.feauture-comparison-table td {
  height: 80px;
  text-align: center;
  padding: 4px 6px;
}

.feauture-comparison-table th {
  font-size: 24px;
}

.feauture-comparison-table td {
  border-bottom: 1px solid #e8ebf1;
  font-size: 16px;
}

.feauture-comparison-table td > div {
  display: flex;
  justify-content: center;
}

.feauture-comparison-table th:nth-child(1),
.feauture-comparison-table td:nth-child(1) {
  text-align: left;
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  table.feauture-comparison-table {
    border: 0px;
  }
  .feauture-comparison-table thead {
    display: none;
  }
  .feauture-comparison-table tr {
    display: block;
    box-shadow: rgb(0 0 0 / 14%) 0px 3px 12px;
    border-radius: 8px;
    margin-bottom: 32px;
  }
  .feauture-comparison-table td {
    display: flex;
    justify-content: flex-end;
    padding: 12px 24px;
    border: none;
    height: fit-content;
  }
  .feauture-comparison-table td:first-child {
    text-align: right;
    padding-top: 24px;
    font-size: 18px;
  }
  .feauture-comparison-table td:last-child {
    padding-bottom: 24px;
  }
  .feauture-comparison-table td:not(:first-child):before {
    flex-grow: 1;
    justify-self: left;
    text-align: left;
    content: attr(data-label);
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
  }
}
