.menu {
  /* box-shadow: 0px 0px 8px silver; */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
}

.menu-item {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  font-size: 16px;
  padding: 0px 12px;
  cursor: pointer;
}

.menu-item-light {
  background-color: #fff;
  color: var(--text-clickable-light);
}

.menu-item-dark {
  background-color: #2a2a2f;
  color: var(--text-clickable-dark);
}

.menu-item-light:hover {
  background-color: #e8ebf1;
}
.menu-item-dark:hover {
  background-color: #35353a;
}

.menu-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.menu-item:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
