.site-footer {
  background-color: #0B0B1A;
}

section.faq {
  padding-top: 96px;
  padding-bottom: 32px;
  display: flex;
  justify-content: center;
  background-image: url("/public/images/faq_bg.svg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

section.faq > .content, section.bottom > .content {
  width: 832px;
  max-width: 92%;
}

section.faq ul {
  padding: 0px;
}

section.faq ul li {
  list-style: none;
}


section.faq > div ul li:not(:first-child) {
  margin-top: 24px;
}

section.faq .question {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
}

section.faq .answer {
  margin-top: 10px;
  margin-left: 32px;
  color: #C8C7CC;
  font-size: 16px;
  line-height: 1.5;
}

section.bottom {
  display: flex;
  justify-content: center;
}

section.bottom > .content {
  color: #DFDFDF;
  font-size: 16px;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 32px; 
}

section.bottom .links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: min(10vw, 56px);
}

section.bottom .links a {
  color: #DFDFDF;
}

section.bottom .copyright {
  opacity: 0.6;
}