.account {
  height: 32px;
  max-width: 300px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.account-light {
  color: var(--text-clickable-light);
}

.account-dark {
  color: var(--text-clickable-dark);
}

.account-light:hover {
  color: var(--primary-color-light);
}

.account-dark:hover {
  color: var(--primary-color-dark);
}