.sign-in-button {
  min-width: 100px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  border-radius: 16px;
  cursor: pointer;
  padding: 0px 24px;
}

.sign-in-button-light {
  color: var(--text-clickable-light);
  border: 1px solid #555;
}

.sign-in-button-dark {
  color: var(--text-clickable-dark);
  border: 1px solid #c8c7cc;
}

.sign-in-button-light:hover {
  border-color: var(--primary-color-light);
}

.sign-in-button-dark:hover {
  border-color: var(--primary-color-dark);
}
