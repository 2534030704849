.hamburger-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 26px;
  height: 20px;
  cursor: pointer;
}

.hamburger-button .inner {
  color: #fff;
  width: inherit;
  height: 3px;
  background-color: var(--text-clickable-dark);
  border-radius: 1px;
}

.hamburger-button-light .inner {
  background-color: var(--text-clickable-light);
}

.hamburger-button-dark .inner {
  background-color: var(--text-clickable-dark);
}

.hamburger-button-light:hover .inner {
  background-color: var(--primary-color-light);
}

.hamburger-button-dark:hover .inner {
  background-color: var(--primary-color-dark);
}

/* animation */

.hamburger-button .inner {
  transition: transform .3s ease-in-out;
}

.hamburger-button-open .inner:nth-child(2) {
  transform: translateX(-26px);
  opacity: 0;
}

.hamburger-button-open .inner:nth-child(1) {
  transform: rotate(45deg) translate(6px, 6px);
}

.hamburger-button-open .inner:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
}
