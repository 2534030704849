.plan-card {
  height: 590px;
  min-width: 300px;
  border-radius: 16px;
  background-color: #fff;
  padding: 56px 36px;
  position: relative;
}

.plan-card header {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.plan-card header h3:nth-of-type(1) {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}

.plan-card header h3:nth-of-type(2) {
  font-size: 34px;
  line-height: 56px;
  font-weight: 700;
}

.plan-card header div {
  font-size: 16px;
  line-height: 20px;
}

.plan-card footer {
  position: absolute;
  bottom: 36px;
  left: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.plan-card ul {
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.plan-card ul li {
  display: flex;
  gap: 8px;
  font-size: 16px;
  line-height: 20px;
  color: #555;
}