.site-header {
  display: flex;
  justify-content: center;
  height: 64px;
  padding: 0px 24px;
  z-index: 1;
  box-shadow: 0px 1px 5px rgba(51, 51, 51, 0.1);
}

.site-header-light {
  background-color: var(--primary-background-light);
}

.site-header-dark {
  background-color: transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  background-color: inherit;
}

/* shared navigation styles (both desktop & mobile) */

.site-header nav {
  display: flex;
  align-items: center;
}

.site-header nav ul {
  display: flex;
}

.site-header nav ul li {
  list-style: none;
  font-size: 18px;
  cursor: pointer;
}

.site-header-light nav ul li {
  color: var(--text-clickable-light);
}

.site-header-dark nav ul li {
  color: var(--text-clickable-dark);
}

.site-header-light nav ul li:hover {
  color: #000;
}

.site-header-dark nav ul li:hover {
  color: #fff;
}

.site-header nav ul li a {
  color: inherit;
  text-decoration: none;
}

/* desktop navigation */

.site-header nav.desktop ul {
  padding-left: 24px;
  gap: 32px;
}

/* mobile navigation */

.site-header nav.mobile {
  z-index: 1;
  background-color: inherit;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  flex-direction: column;
}

.site-header nav.mobile a {
  align-self: flex-start;
}

.site-header nav.mobile ul {
  width: 100%;
  flex-direction: column;
  gap: 0px;
  line-height: 48px;
  padding-left: 0px;
}

.site-header-dark nav.mobile ul {
  background-color: #18153B;
  box-shadow: 0px 2px 3px #333;
}

.site-header-light nav.mobile ul {
  box-shadow: 0px 2px 3px lightgray;
}

.site-header nav.mobile ul li {
  text-align: center;
}

.site-header-light nav.mobile ul li:hover {
  background-color: whitesmoke;
}

.site-header-dark nav.mobile ul li:hover {
  background-color: #0F0D22;
}

.site-header nav.mobile ul li a {
  display: block;
}