@import url("./reset.css");
@import url("./antd-reset.css");

@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");

:root {
  --primary-action-color: #027bff;
  --primary-background-light: #fff;
  --primary-background-dark: #1c1c1f;
  --primary-color-light: #000;
  --primary-color-dark: #fff;
  --text-clickable-light: #333;
  --text-clickable-dark: #dfdfdf;
  --max-content-width: 1200px;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Hiragino Sans GB", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* override rc-menu styles */
.rc-menu-item-active {
  background-color: whitesmoke;
}
