.container {
  padding: 15vh 4vw;
  display: flex;
  justify-content: center;
}

.content {
  width: var(--max-content-width);
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.container h2 {
  font-size: 48px;
  line-height: 58px;
  font-weight: 800;
  color: var(--text-clickable-light);
}

.container h3 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  margin: 24px auto 36px;
  color: var(--text-clickable-light);
}

.container .email, .container a {
  color: inherit;
  margin: 0px .3em;
}

.container .email {
  font-weight: 700;
}

.container .more-info {
  margin-top: 48px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text-clickable-light);
  opacity: .7;
  padding: 0px min(6vw, 200px);
}