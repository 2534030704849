.plan-button {
  width: 174px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.free-plan-button {
  color: #027bff;
}

.personal-plan-button {
  color: #fff;
  border-radius: 36px;
  background: linear-gradient(22.41deg, #1bb3e3 1.34%, #0267ff 99.23%);
}

.business-plan-button {
  color: #fff;
  border-radius: 36px;
  background: linear-gradient(22.41deg, #e754ce 1.34%, #934ef4 99.23%);
}