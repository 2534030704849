.plan-card {
  min-width: 320px;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.14);
  height: 450px;
  padding: 48px;
  border-radius: 16px;
  cursor: pointer;
}

.plan-card > header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 32px;
}

.plan-card > header .plan-title {
  font-size: 24px;
  font-weight: 700;
}

.plan-card > ol {
  padding: 0px;
  list-style: none;
  color: #555;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.plan-card > ol > li {
  display: flex;
  align-items: center;
  gap: 8px;
}