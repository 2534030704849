.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 194px 6vw;
}

.title {
  font-size: 48px;
  line-height: 58px;
  font-weight: 800;
  text-align: center;
}

.input-container {
  height: 64px;
  border: 2px solid #C8C7CC;;
  max-width: 96%;
  width: 560px;
  margin: 24px auto 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 28px;
  border-radius: 32px;
  padding: 0px 32px;
  color: var(--text-clickable-light);
}

.input-container input {
  padding: 0px;
  border: none;
  outline: none;
  text-align: right;
}

.input-container input::placeholder {
  color: #C8C7CC;
}

.description {
  font-size: 18px;
  line-height: 22px;
  width: 560px;
  max-width: 96%;
  text-align: center;
  margin-bottom: 48px;
  color: var(--text-clickable-light);
}

.next-button {
  background-color: var(--primary-action-color);
  color: #fff;
  border-radius: 32px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 36px;
  min-width: 160px;
  height: 56px;
  border: none;
  cursor: pointer;
}