/* call for action */

section.call-for-action {
  position: relative;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 1440px auto, cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 800px;
  padding: 0px 6vw;
  margin-bottom: 200px;
}

section.call-for-action button.compare-plans-button {
  margin-top: 64px;
  background-color: var(--primary-action-color);
  width: 216px;
  height: 56px;
  border-radius: 36px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  outline: none;
  border: none;
  cursor: pointer;
}

section.call-for-action .intro-video {
  max-width: 90%;
  aspect-ratio: 16 / 9;
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 12px;
}

@media screen and (max-width: 768px) {
  section.call-for-action {
    height: 700px;
  }
}

@media screen and (max-width: 480px) {
  section.call-for-action {
    margin-bottom: 140px;
  }
  section.call-for-action .intro-video {
    bottom: -60px;
  }
}

/* choose plan section */

section.choose-plan {
  padding: 0 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

section.choose-plan .plans {
  display: flex;
  justify-content: space-between;
  gap: 56px;
  flex-wrap: wrap;
  width: var(--max-content-width);
  max-width: 100%;
}

section.choose-plan .learn-more {
  display: flex;
  align-items: center;
  color: var(--primary-action-color);
  text-decoration: none;
  gap: 8px;
  font-size: 20px;
  font-weight: 700;
  margin: 48px auto 64px;
  padding: 0px 12px;
  cursor: pointer;
  width: fit-content;
}
