.feature-intro {
  height: 480px;
  display: flex;
  gap: 4vw;
  justify-content: center;
}

.feature-intro > .half {
  flex: 1;
  max-width: calc(var(--max-content-width) / 2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-intro > .half > img,
.feature-intro > .half > div {
  width: 70%;
}

.feature-description {
  font-size: 18px;
  line-height: 20px;
  color: #333;
  padding-left: 16px;
}

.feature-description li {
  line-height: 1.8;
}

/* mobile styles */

.feature-intro-mobile {
  height: fit-content;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding-top: 64px;
  padding-bottom: 64px;
}

.feature-intro-mobile > .half {
  width: 100%;
}